/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Global } from '@emotion/core';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Global styles={styles.global} />
      <main style={styles.main}>{children}</main>
    </>
  );
};

export default Layout;

const styles = {
  global: {
    a: {
      textDecoration: 'none',
    },
  },
  main: {
    display: 'flex',
    minHeight: '100vh',
  },
};
